<template>
    <path id="Vector" d="M6 155.74L43.3699 81H143.114L180.484 155.74L143.114 230.48H43.3699L6 155.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
        d="M39.8699 76H145.794L185.664 155.74L145.794 235.48H39.8699L0 155.74L39.8699 76ZM46.0503 86L11.1803 155.74L46.0503 225.48H139.614L174.484 155.74L139.614 86H46.0503Z"
        fill="#D4A168" />
    <path id="Vector_2" d="M6 314.74L43.3699 240H143.114L180.484 314.74L143.114 389.48H43.3699L6 314.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
        d="M39.8699 235H145.794L185.664 314.74L145.794 394.48H39.8699L0 314.74L39.8699 235ZM46.0503 245L11.1803 314.74L46.0503 384.48H139.614L174.484 314.74L139.614 245H46.0503Z"
        fill="#D4A168" />
    <path id="Vector_3" d="M6 471.74L43.3699 397H143.114L180.484 471.74L143.114 546.48H43.3699L6 471.74Z" fill="#D4A168" />
    <path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
        d="M39.8699 392H145.794L185.664 471.74L145.794 551.48H39.8699L0 471.74L39.8699 392ZM46.0503 402L11.1803 471.74L46.0503 541.48H139.614L174.484 471.74L139.614 402H46.0503Z"
        fill="#D4A168" />
</template>